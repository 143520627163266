<template>
	<div class="container">
		<top-header></top-header>
		<div class="user width-1300">
			<div class="pc-user">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/index'}">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/user'}">个人中心</el-breadcrumb-item>
					<el-breadcrumb-item>基本设置</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="label">个人中心</div>
				<div class="user_center">
					<div class="top-option">
						<div class="item active" @click="gourl('/user')">
							<span>基本设置</span>
						</div>
						<!-- <div class="item" @click="gourl('/cloudData')">
							<span>点云数据</span>
						</div> -->
						<div class="item" @click="gourl('/messageList')">
							<span>留言</span>
						</div>
					</div>
					<div class="info">
						<div class="text">基本设置</div>
						<div class="left" v-if="info">
							<div class="item">
								<p>公司名称：</p>
								<p>{{info.company.name}}</p>
							</div>
							<div class="item">
								<p>所属部门：</p>
								<p>{{info.department}}</p>
							</div>
							<div class="item">
								<p>微信号：</p>
								<p>{{info.wx_number ? info.wx_number : '暂无微信号'}}</p>
							</div>
							<div class="item">
								<p>手机号：</p>
								<p>{{info.phone}}</p>
								<button class="update-btn" @click="updatePass" v-if="!isShow">修改登录密码</button>
								<div class="btn round" @click="sendSms()" v-if="!isDisabled && isShow">{{content}}</div>
								<div class="btn round wait" v-if="isDisabled">{{content}}</div>
							</div>
							<div class="" v-if="isShow">
								<div class="item">
									<p>验证码：</p>
									<input type="number" v-model="code">
								</div>
								<div class="item">
									<p>旧密码：</p>
									<input type="password" v-model="used_password">
								</div>
								<div class="item">
									<p>新密码：</p>
									<input type="password" v-model="password">
								</div>
								<div class="item">
									<p>确认密码：</p>
									<input type="password" v-model="confirm_password">
								</div>
								<div class="item">
									<el-button type="primary" @click="passSubmit">保存</el-button>
								</div>
							</div>
						</div>
						<div class="right" v-if="info">
							<div class="user_img"><img :src="$store.state.imaUrl + info.avatarUrl" alt=""></div>
							<el-upload class="upload-demo" :action="$store.state.httpUrl + 'front/reset/avatar'" :limit="1" name="avatar" :headers="headers"
								:on-change="imageFileEdit" ref="imageload" :show-file-list="false" accept=".jpg,.png,.jpeg,.gif" :on-success="headerSuccess">
								<el-button icon="el-icon-upload2">更换头像</el-button>
							</el-upload>
						</div>
					</div>
				</div>
			</div>
			<div class="mod-user">
				<div class="info" v-if="info">
					<div class="portrait" @click="upinfo">
						<div class="user_img"><img :src="$store.state.imaUrl + info.avatarUrl" alt=""></div>
						<div>
							<i class="iconfont iconbianji"></i>
						</div>
					</div>
					<div class="name">
						<span>{{info.company.name}}</span>
					</div>
					<div class="phone">
						<span>{{info.phone}}</span>
					</div>
				</div>
				<div class="list" @click="gourl('/updatePassword')">
					<img src="../../../static/images/icon/xgmm.png" alt="">
					<span>修改密码</span>
				</div>
				<!-- <div class="list" @click="gourl('/cloudData')">
					<i class="iconfont iconshuju"></i>
					<span>我的点云数据</span>
				</div> -->
				<div class="list" @click="gourl('/messageList')">
					<img src="../../../static/images/icon/ly.png" >
					<span>留言</span>
				</div>
			</div>
			<div class="button-btn" @click="signOut">
				<span>退出登录</span>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import Cookie from 'js-cookie';
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
		},
		data() {
			return {
				headers:{
					'Front-Remember-Token':Cookie.get('frontRemembertoken')
				},
				isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
				content: '发送验证码', // 发送验证码按钮的初始显示文字
				timer: null,
				count: '',
				model: {},
				isShow: false,
				info: '',
				code: '',
				confirm_password: '',
				used_password: '',
				password: ''
			}
		},
		created() {
			this.getinfo();
		},
		methods: {
			signOut() {
				this.$notify({
					title: "提示",
					message: "操作成功！",
					type: "success",
				});
				Cookie.remove("frontRemembertoken");
				Cookie.remove("userinfo");
				this.username = '';
				this.$router.push("/");
			},
			headerSuccess(){
				location.reload(true);
			},
			passSubmit() {
				if (this.code == '') {
					this.$notify.error({
						title: '提示',
						message: '验证码不可为空！'
					});
					return false;
				}
				if (this.used_password == '') {
					this.$notify.error({
						title: '提示',
						message: '请输入旧密码！'
					});
					return false;
				}
				if (this.password == '') {
					this.$notify.error({
						title: '提示',
						message: '请输入新密码！'
					});
					return false;
				}
				if (this.confirm_password != this.password) {
					this.$notify.error({
						title: '提示',
						message: '俩次密码不一致！'
					});
					return false;
				}
			},
			getinfo() {
				this.$get('front/user/get_details', {}).then(res => {
					this.info = res.data.data;
				})
			},
			gourl(url) {
				this.$router.push(url)
			},
			upinfo() {
				this.$router.push('/updateinfo');
			},
			updatePass() {
				this.isShow = true;
			},
			imageFileEdit(file, fileList) {
				if (fileList.length > 1) { //这里只获取最后一次选择的问津
					fileList.splice(0, 1);
				}
				if (fileList.length > 0) {
					let fileName = fileList[0].name.substring(fileList[0].name.lastIndexOf('.') + 1);
					let extension1 = fileName === 'png';
					let extension2 = fileName === 'jpg';
					let extension3 = fileName === 'jpeg';
					let extension4 = fileName === 'gif';
					if (!extension1 && !extension2 && !extension3 && !extension4) {
						this.$refs.imageload.clearFiles();
						this.$notify({
							type: 'error',
							title: '提示',
							message: '请上传正确格式的文件',
						});
						return false;
					} else {
						this.$refs.imageload.submit();
					}
				}
			},
			sendSms() {
				let vm = this
				// 校验手机号
				// if (!vm.model.username || vm.model.username == '') {
				// 	vm.$layer.msg('请输入手机号')
				// 	return
				// }
				// if (!(/^1[34578]\d{9}$/.test(vm.model.username))) {
				// 	vm.$layer.msg('请输入正确的手机号')
				// 	return
				// }
				// 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
				// 控制倒计时及按钮是否可以点击
				const TIME_COUNT = 60
				vm.count = TIME_COUNT
				vm.timer = window.setInterval(() => {
					if (vm.count > 0 && vm.count <= TIME_COUNT) {
						// 倒计时时不可点击
						vm.isDisabled = true
						// 计时秒数
						vm.count--
						// 更新按钮的文字内容
						vm.content = vm.count + 's后重新发送'
					} else {
						// 倒计时完，可点击
						vm.isDisabled = false
						// 更新按钮文字内容
						vm.content = '发送验证码'
						// 清空定时器!!!
						clearInterval(vm.timer)
						vm.timer = null
					}
				}, 1000)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container{
		background-color: #E8E9ED;
		@media screen and(min-width:320px) and (max-width:767px) {
			background-color: #fff;
		}
	}
	.button-btn {
		padding: 15px 0;
		margin: 0 10px;
		text-align: center;
		background-color: #00337D;
		color: #fff;
		border-radius: 5px;
		font-size: 14px;
		display: none;

		@media screen and(min-width:320px) and (max-width:767px) {
			display: block;
			position: absolute;
			left: 0;
			right: 0;
			bottom: 30px;
		}
	}

	.user {
		margin-top: 60px;
		position: relative;
		padding-top: 20px;
		padding-bottom: 1px;
		@media screen and(min-width:320px) and (max-width:767px) {
			padding: 0 0;
			margin-top: 0;
			min-height: 100vh;
		}

		.mod-user {
			display: none;

			@media screen and(min-width:320px) and (max-width:767px) {
				display: block;
			}

			.list {
				font-size: 14px;
				border-bottom: 1px solid #E2E2E2;
				color: #666;
				padding: 15px;
				margin:0 20px;
				span {
					vertical-align: middle;
				}

				box-sizing: border-box;

				img {
					margin-right: 15px;
					vertical-align: middle;
					width: 25px;
					height: 25px;
				}
			}

			.info {
				background: url(../../../static/images/bg2.png) no-repeat 0 0;
				background-size: cover;
				height: 300px;
				padding-top: 100px;
				margin-bottom: 20px;
				box-sizing: border-box;
				color: #1A1A1A;
				@media screen and(min-width:320px) and (max-width:767px) {
					margin-bottom: 0;
				}
				.name,
				.phone {
					text-align: center;
					margin-top: 10px;
					font-size: 14px;
				}

				.portrait {
					border-radius: 50%;
					width: 90px;
					height: 90px;
					margin: 0 auto;
					position: relative;
					border: 2px solid #888;
					.user_img{
						width: 90px;
						height: 90px;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						overflow: hidden;
						background-color: #fff;
						margin: 0 auto;
						position: absolute;
						right: 0;
						img {
							height: 100%;
						}
					}
					div {
						position: absolute;
						bottom: 0;
						right: 3px;
						width: 23px;
						height: 23px;
						border-radius: 50%;
						text-align: center;
						background-color: #828282;

						.iconfont {
							font-size: 10px;
							color: #fff;
						}
					}
				}
			}
		}

		.pc-user {
			@media screen and(min-width:320px) and (max-width:767px) {
				display: none;
			}

			.label {
				font-size: 26px;
				letter-spacing: 2px;
				margin: 30px 0;
			}
			.user_center{
				background-color: #fff;
				margin-bottom: 50px;
				box-sizing: border-box;
				height: 70.4vh;
				padding-top: 20px;
				.info{
					display: inline-block;
					margin-left: 2%;
					width: 75%;
					.text{
						font-size: 24px;
						font-weight: 500;
						letter-spacing: 2px;
						margin-bottom: 20px;
					}
					.left {
						display: inline-block;
						margin-right: 70px;
						.item {
							margin-bottom: 20px;
							font-size: 14px;
					
							.el-button {
								padding: 12px 40px;
								border-color: #00337D;
								background: #00337D;
							}
					
							.round {
								display: inline-block;
								color: #0064C8;
								width: 95px;
								margin-left: 30px;
								cursor: pointer;
							}
					
							.wait {
								cursor: not-allowed;
								color: #E0E0E0;
							}
					
							p {
								display: inline-block;
								text-align: left;
					
								&:nth-child(1) {
									width: 100px;
								}
							}
					
							input {
								border: 1px solid #E0E0E0;
								height: 30px;
								width: 200px;
								border-radius: 5px;
								outline: none;
								transition: border-color .3s;
					
								&:focus {
									border-color: #0064C8;
								}
							}
					
							.update-btn {
								border: 0;
								width: 95px;
								background-color: #fff;
								color: #0064C8;
								margin-left: 30px;
								cursor: pointer;
							}
						}
					}
					.right {
						display: inline-block;
						vertical-align: top;
						margin-left: 30px;
						.user_img{
							width: 170px;
							height: 170px;
							overflow: hidden;
							border-radius: 50%;
							display: flex;
							justify-content: center;
							align-items: center;
							border: 1px solid #e5e5e5;
						}
						img {
							height: 100%;
						}
					
						.upload-demo {
							text-align: center;
							margin-top: 20px;
					
							// .el-button {
							// 	border-color: #00337D;
							// 	background: #00337D;
							// }
						}
					}
				}
			}
			.top-option {
				display: inline-block;
				vertical-align: top;
				width: 20%;
				border-right: 1px solid #e5e5e5;
				height: 100%;
				.item {
					padding: 13px 20px;
					font-size: 14px;
					cursor: pointer;
					transition: background-color .3s;
					&:hover {
						background-color: #E5EAF2;
						color: #00337D;
						border-right: 3px solid #00337D;
					}
				}

				.active {
					background-color: #E5EAF2;
					color: #00337D;
					font-weight: 800;
					border-right: 3px solid #00337D;
				}
			}
		}
	}
</style>
